import React from 'react'

const Contact = () => {
  return (
    <div name='contact' className='w-full h-full bg-[#ffffff] flex justify-center items-center p-10'>
        <form method='POST' action="https://getform.io/f/DbDGGBal" className='flex flex-col max-w-[600px] w-full'>
            <div className='pb-8'>
                <p className='text-4xl font-bold inline border-b-4 border-[#F8A218] text-black'>Contact</p>
                <p className='text-black py-4'>Submit the form below or shoot me an email - mdt21@verizon.net</p>
            </div>
            <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' name='name' />
            <input className='my-4 p-2 bg-[#ccd6f6]' type="email" placeholder='Email' name='email' />
            <textarea className='bg-[#ccd6f6] p-2' name="message" rows="10" placeholder='Message'></textarea>
            <button className='text-black border-black border-2 hover:bg-[#F8A218] hover:border-[#F8A218] px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
        </form>
    </div>
  )
}

export default Contact